<script>
import { computed, defineComponent, h, onMounted, resolveComponent, watch } from 'vue';

import dayjs from "dayjs";
import { merge } from "lodash";

import { genGUID, isAsync, isVisible } from '@/core/helpers/utils';
import stateStore from "@/core/store/index";

export default defineComponent({
	props: {
		store: {
			type: Object,
			default: null
		},
		config: {
			type: Object,
			default: () => ({})
		},
		data: {
			type: Object,
			default: () => ({})
		},
		defaults: {
			type: Object,
			default: () => ({})
		},
		copy: {
			type: Boolean,
			default: false
		},
		readonlyForm: {
			type: Boolean,
			default: false
		},
		watchChange: {
			type: Boolean,
			default: false
		}
	},

	methods: {
		createForm() {
			const fields = this.store.state.fields;

			const result = [];

			for (const key in fields) {
				const field = fields[key];

				if (!('visible' in field.config && !field.config.visible)) {
					const elements = [];

					if (typeof field.type == 'object' && field.type.table) {
						result.push(h(resolveComponent('DBGrid'), { form: this, field: key }));
					} else {
						elements.push(
							h(
								resolveComponent('DBEdit'),
								{
									form: this,
									field: key,
									label: !(typeof field.type == 'string' && field.type == 'BOOLEAN')
								}
							)
						);

						result.push(
							h(
								'div',
								{ class: 'form-row' },
								h('div', { class: 'col' }, elements)
							)
						)
					}
				}
			}

			return result;
		}
	},

	computed: {
		readonly: {
			get() {
				return this.store.state.readonly;
			},
			set(value) {
				this.store.state.readonly = value;
			}
		}
	},

	render() {
		if (this.store.state.loaded) {
			return h('form', { name: this.name }, this.$slots.default ? this.$slots.default(this) : this.createForm());
		} else {
			return null;
		}
	},

	setup(props) {
		const validators = [];

		const fields = {};

		const name = `form_${genGUID()}`;

		/**
		 * Добавление валидатора для едита, вызывается из едита
		 */
		const addValidation = (validation) => validators.push(validation);

		const delValidation = (validation) => {
			for (const key in validators) {
				if (validators[key] == validation) {
					validators.splice(key, 1);

					break;
				}
			}
		}

		const findElementController = (target) => {
			for (const key in fields) {
				if (fields[key].el && fields[key].el.value == target) return fields[key];
			}

			return null;
		}

		/**
		 * Вызывается из едитов для перевода фокуса на следующее поле
		 */
		const nextControl = (target) => {
			if (target.target.nodeName == 'INPUT' || target.target.nodeName == 'TEXTAREA') {
				target.preventDefault();

				const elements = document.forms[name].getElementsByClassName('form-control');

				if (elements.length) {
					for (const key in elements) {
						const element = elements[key]
						if (element == target.target) {
							let thisControl = +key;
							let thisController = null;
							let skip = false;

							do {
								thisControl++;

								if (thisControl >= elements.length) thisControl = 0;

								thisController = findElementController(elements[thisControl]);

								skip = !thisController || !(elements[thisControl].offsetWidth > 0 || elements[thisControl].offsetHeight > 0);

								if (thisController) {
									thisController.onFocusOpenPopup();

									if (thisController.structure.type == 'BOOLEAN' && thisController.readonly.value) skip = true;
								}
							} while (skip);

							elements[thisControl].focus();

							break;
						}
					}
				}
			}
		}

		/**
		* Добавление в стор blob данных
		* @param blob - BLOB данные
		* @param name - имя файла
		* @param filesTable имя табличного поля в сторе
		* @param id - ключ записи
		*/
		const addBlob = (blob, name, tableFiles = 'files', id = genGUID()) => {
			props.store.files[id] = blob;

			const storeSetPlan = props.store.createDBStore(tableFiles);

			return storeSetPlan.push({
				id,
				name,
				size: blob.size,
				type: blob.type,
				lasteditor: stateStore.state.user['employee'],
				_lasteditor: stateStore.state.user['_employee'],
				updated_at: dayjs().format(),
				author: stateStore.state.user['employee'],
				_author: stateStore.state.user['_employee'],
				created_at: dayjs().format()
			});
		}

		onMounted(async () => {
			if (props.store.model) {
				const readonly = props.store.state.readonly;

				props.store.createState(props.config);

				props.store.state.readonly = readonly || props.readonlyForm;

				//Табличная часть или офллайн таблица
				if (props.store.model.subtable || props.store.model.offLine) {
					if (props.copy) {
						props.store.loadData(
							merge(
								{},
								props.data,
								props.defaults
							),
							true
						);

						props.store.isNew.value = true;
					} else {
						if (props.store.model.key) {
							if (props.data[props.store.model.key]) {
								props.store.loadData(props.data);
							} else {
								props.store.loadData(
									Object.assign(
										{
											[props.store.model.key]: genGUID()
										},
										props.data,
										props.defaults
									)
								);

								props.store.isNew.value = true;
							}
						} else {
							props.store.loadData(
								Object.assign(
									props.data ? props.data : {},
									props.defaults
								)
							);
						}
					}
				} else {
					//Если есть первичный ключь, тогда запись на редактирование
					if (props.data[props.store.model.key]) {
						if (props.copy) {
							await props.store.copyData(props.data[props.store.model.key], props.defaults);

							props.store.isNew.value = true;
						} else {
							await props.store.fetchData(props.data[props.store.model.key]);
						}

						//Иначе новая запись
					} else {
						const notion = {};

						//Установка владелца для новой записи
						if (props.store.owner && props.store.model.depends && props.store.model.ownerField) {
							notion[props.store.model.ownerField] = props.store.owner;
						}

						//Запрос представлений для новой записи
						const data = await props.store.notion(
							props.store.name,
							Object.assign(
								{
									[props.store.model.key]: genGUID()
								},
								props.data,
								props.defaults,
								notion
							)
						);

						props.store.loadData(data);

						props.store.isNew.value = true;
					}
				}

				if (props?.config?.onCreate) {
					props.config.onCreate(props.store);
				}

				props.store.setWatching();

				props.store.createHash()
			}
		})

		/**
		* Валидация формы
		*/
		const validation = async () => {
			let result = true;

			for (const validator of validators) {
				if ((isAsync(validator) ? await validator() : validator()) !== false) {
					result = false;
				}
			}

			return result;
		}

		/**
		 * Сохранение данных формы
		 */
		const save = async (noValidation = false) => {
			if (noValidation || await validation()) {
				for (const key in fields) {
					if (fields[key].beforeSave) {
						await fields[key].beforeSave();
					}
				}

				const result = await props.store.save();

				for (const key in fields) {
					if (fields[key].afterSave) {
						await fields[key].afterSave();
					}
				}

				return result;
			} else {
				return false;
			}
		}

		const setField = (controller) => {
			fields[controller.labelId] = controller;
		}

		const deleteField = (controller) => {
			if (fields[controller.labelId]) delete fields[controller.labelId];
		}

		const setFocus = (nameField) => {
			setTimeout(() => {
				for (const key in fields) {
					if (fields[key].field.value == nameField) {
						fields[key].setFocusInput();

						break;
					}
				}
			}, 100)
		}

		const updateTextareaHeight = () => {
			for (const key in fields) {
				if (fields[key].el) {
					const el = fields[key].el.value;

					if (isVisible(el) && el.nodeName == 'TEXTAREA') fields[key].updateTextareaHeight();
				} else if (fields[key].inputs) {
					fields[key].updateTextareaHeight();
				}
			}
		}

		const getField = (field) => {
			const result = [];

			for (const key in fields) {
				if (fields[key].field.value == field) result.push(fields[key]);
			}

			return result;
		}

		const getData = async (noValidation = false) => noValidation ? props.store.data : (await validation() ? props.store.getData(props.store.data) : null);

		watch(
			() => props.readonlyForm,
			(newValue) => props.store.state.readonly = newValue
		)

		return {
			name,
			addValidation,
			delValidation,
			nextControl,
			save,
			//eslint-disable-next-line
			data: props.store.data,
			loaded: props.store.state.loaded,
			addBlob,
			validation,
			isNew: computed(() => props.store.isNew.value),
			isChanged: computed(() => props.watchChange ? props.store.changed : false),
			getField,
			setField,
			deleteField,
			setFocus,
			fields,
			updateTextareaHeight,
			getData
		}
	}
})
</script>
