<script>
import { computed, defineComponent, onBeforeUpdate } from 'vue';

import DBStoreRecord from '@/core/db_store_record';
import { genGUID } from '@/core/helpers/utils';

export default defineComponent({
	props: {
		form: {
			type: Object,
			default: null
		},
		field: {
			type: String,
			default: null
		},
		readonlyForm: {
			type: Boolean,
			default: null
		},
		config: {
			type: Object,
			default: () => ({})
		}
	},

	setup(props, { slots }) {
		const formStore = props.form.store;

		const field = formStore.model.fields[props.field];
		if (!field) return null;

		const storeData = formStore.data[props.field];
		if (!storeData) return null;

		const stores = [];

		const createStores = () => {
			stores.splice(0, stores.length);

			storeData.forEach((data, row) => {
				const store = new DBStoreRecord(field.table);
				store.model = field.type.table;

				store.createState(Object.assign({}, formStore.state.fields[props.field], props.config));

				store.files = formStore.files;
				store.state.readonly = typeof props.readonlyForm == 'boolean' ? props.readonlyForm : formStore.state.readonly;

				store.data = data;

				store.setWatching();

				stores.push({
					id: genGUID(),
					row,
					data,
					form: {
						store,
						addValidation: props.form.addValidation,
						delValidation: props.form.delValidation,
						nextControl: props.form.nextControl,
						setField: props.form.setField,
						getField: props.form.getField,
						deleteField: props.form.deleteField,
						save: props.form.save,
						isNew: props.form.isNew,
						readonly: computed(() => typeof props.readonlyForm == 'boolean' ? props.readonlyForm : formStore.state.readonly)
					}
				})
			});
		}

		onBeforeUpdate(() => {
			stores.forEach((el) => el.form.store.stopWatching());
		})

		return () => {
			createStores();

			return stores.map((el) => slots.default(el));
		}
	}
})

</script>

<style></style>